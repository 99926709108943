<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="font-weight-black">
      <span>
        Veículos<br />
        <v-progress-circular
          v-if="carregandoRemoverVeiculo || carregandoBuscarVeiculos"
          indeterminate
          :size="15"
          :width="2"
          color="primary"
          class="ml-2"
        />
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row no-gutters>
        <v-col class="no-gutters pb-2">
          <i
            v-if="
              Object.keys(veiculos).length <= 0 && !carregandoBuscarVeiculos
            "
            class="grey--text"
          >
            <small>Nenhum veiculo adicionado</small>
          </i>
          <v-chip
            v-for="(veiculo, key) in veiculos"
            v-else
            :key="key"
            class="my-1 mr-1"
            close
            label
            close-icon="mdi-delete"
            outlined
            @click="detalhesVeiculo(veiculo)"
            @click:close="removerVeiculo(veiculo)"
          >
            <template v-if="veiculo.status_alerta === 1">
              <span style="color: green">{{ veiculo.placa_veiculo }} </span>
            </template>
            <template v-else>
              <span style="color: red">{{ veiculo.placa_veiculo }} </span>
            </template>
          </v-chip>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="!seeOnly">
        <v-col class="no-gutters">
          <v-btn
            outlined
            small
            color="success"
            :disabled="
              carregandoBuscarVeiculos ||
              carregandoRemoverVeiculo ||
              carregandoSalvarOcorrencia
            "
            :loading="carregandoSalvarOcorrencia"
            @click="adicionar"
          >
            <v-icon left v-text="'mdi-plus'" />
            Adicionar
          </v-btn>
          <dialog-inserir-veiculo-ocorrencia
            :mostrar="mostrarDialogInserirVeiculo"
            :ocorrencia-id="ocorrenciaId"
            @fechar="mostrarDialogInserirVeiculo = false"
            @salvou="salvouVeiculo"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <dialog-detalhes-veiculo
      :mostrar="mostrarDialogDetalhesVeiculo"
      :veiculo="veiculoDetalhes"
      :diasSemana="diaSemana"
      :formatoIsoData="formatoIsoData"
      @fechar="mostrarDialogDetalhesVeiculo = false"
    />
    <!-- :validadeAlerta="validadeAlerta"
      :diasSemana="diasSemana" -->
  </v-expansion-panel>
</template>

<script>
import ocorrenciasApi from "@/api/ocorrencias";
import dataFormatada from "@/utils/formatar-data-input";
export default {
  components: {
    DialogInserirVeiculoOcorrencia: () => import("@/components/dialog/DialogInserirVeiculoOcorrencia"),
    DialogDetalhesVeiculo: () => import("@/components/dialog/DialogDetalhesVeiculo"),
  },

  props: {
    ocorrenciaId: {
      type: Number,
      default: 0,
    },
    carregandoSalvarOcorrencia: {
      type: Boolean,
      default: false,
    },
    reload: {
      type: Number,
      default: 0,
    },
    seeOnly: {
      type: Boolean,
      default:false,
    },
  },

  data() {
    return {
      mostrarDialogDetalhesVeiculo: false,
      mostrarDialogInserirVeiculo: false,
      carregandoBuscarVeiculos: false,
      carregandoRemoverVeiculo: false,
      veiculos: [],
      veiculoDetalhes: null,
      formatoIsoData: null,
      diaSemana: null,
    };
  },

  mounted() {
    this.buscarVeiculos();
  },

  methods: {
    async adicionar() {
      if (!this.ocorrenciaId) {
        this.$emit("adicionar-sem-ocorrencia");
        return;
      }

      this.mostrarDialogInserirVeiculo = true;
    },

    async buscarVeiculos() {
      if (!this.ocorrenciaId) return;
      try {
        this.carregandoBuscarVeiculos = true;
        const resposta = await ocorrenciasApi.buscarVeiculos(this.ocorrenciaId);
        this.veiculos = resposta.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.carregandoBuscarVeiculos = false;
      }
    },

    async salvouVeiculo() {
      this.mostrarDialogInserirVeiculo = false;
      this.buscarVeiculos();
    },

    async removerVeiculo(veiculo) {
      try {
        this.carregandoRemoverVeiculo = true;
        await ocorrenciasApi.removerVeiculo(veiculo.id);
        this.buscarVeiculos();
      } catch (e) {
        console.log(e);
      } finally {
        this.carregandoRemoverVeiculo = false;
      }
    },

    async detalhesVeiculo(veiculo) {
      this.formatoIsoData = dataFormatada.formatoIsoDatasemHorario(
        veiculo.validade_alerta
      );
      this.veiculoDetalhes = veiculo;
      if (veiculo.dias_semana) {
        const array = [];
        array.push(veiculo.dias_semana.split(",").map(Number));
        array.map((item) => {
          this.diaSemana = item;
        });
      }
      this.mostrarDialogDetalhesVeiculo = true;
    },
  },
};
</script>
